import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  TextField,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import { Theme } from '@material-ui/core/styles';

interface StyleProps {
  theme: Theme;
}

interface Service {
  id: number | string;
  name: string;
  basePrice: number;
  quantity: number;
  checked: boolean;
}

interface CrossSellModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      minWidth: 600,
    },
  },
  dialogTitle: {
    padding: theme.spacing(3),
    '& h2': {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
  subtitle: {
    color: '#666',
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3, 3),
  },
  serviceRow: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  additionalServiceRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 180px 140px 140px 80px',
    gap: theme.spacing(2),
    color: '#666',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    minWidth: 'auto',
    padding: theme.spacing(0.5),
    color: '#EF4444',
    '&:hover': {
      backgroundColor: 'rgba(239, 68, 68, 0.04)',
    },
  },
  quantityControl: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    '& input': {
      width: 40,
      textAlign: 'center',
      border: 'none',
      padding: theme.spacing(0.5),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  iconButton: {
    padding: 4,
    color: '#6366F1',
    '&.Mui-disabled': {
      color: '#E0E0E0',
    },
  },
  priceField: {
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F9FAFB',
    },
  },
  totalPrice: {
    color: '#333',
    fontWeight: 500,
  },
  checkbox: {
    color: '#6366F1',
    '&.Mui-checked': {
      color: '#6366F1',
    },
  },
  serviceAction: {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid #E0E0E0',
    marginLeft: -25,
    marginRight: -25,
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
  cancelButton: {
    textTransform: 'none',
    color: '#666',
    marginRight: theme.spacing(1),
  },
  saveButton: {
    textTransform: 'none',
    backgroundColor: '#6366F1',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4F46E5',
    },
  },
  serviceNameField: {
    '& .MuiOutlinedInput-root': {
      height: 40,
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
  },
}));

const CrossSellModal: React.FC<CrossSellModalProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const [services, setServices] = useState<Service[]>([
    { id: 1, name: 'Snacks', basePrice: 500, quantity: 1, checked: false },
    { id: 2, name: 'Tea', basePrice: 500, quantity: 1, checked: false },
    { id: 3, name: 'Barbeque', basePrice: 500, quantity: 1, checked: false },
    { id: 4, name: 'Spa', basePrice: 500, quantity: 1, checked: false },
    { id: 5, name: 'Alcohol', basePrice: 500, quantity: 1, checked: false },
    { id: 6, name: 'Extra Mattress', basePrice: 500, quantity: 1, checked: false },
  ]);
  const [additionalServices, setAdditionalServices] = useState<Service[]>([]);
  const [disabledServices, setDisabledServices] = useState<boolean>(false);

  const handleAddService = (): void => {
    const newService: Service = {
      id: `new-${additionalServices.length + 1}`,
      name: '',
      basePrice: 0,
      quantity: 1,
      checked: false,
    };
    setAdditionalServices([...additionalServices, newService]);
  };

  const handleServiceQuantityChange = (id: number | string, increment: number): void => {
    const updatedServices = services.map((service) =>
      service.id === id
        ? { ...service, quantity: Math.max(1, service.quantity + increment) }
        : service
    );
    setServices(updatedServices);
  };

  useEffect(() => {
    if (additionalServices.length > 0) {
      setDisabledServices(
        additionalServices.some((service) => service.name === '' || service.basePrice === 0)
      );
    } else {
      setDisabledServices(false);
    }
  }, [additionalServices]);

  const handleDeleteService = (index: number): void => {
    const updatedServices = additionalServices.filter((_, i) => i !== index);
    setAdditionalServices(updatedServices);
  };

  const handleAdditionalServiceQuantityChange = (index: number, increment: number): void => {
    const updatedServices = additionalServices.map((service, i) =>
      i === index
        ? { ...service, quantity: Math.max(1, service.quantity + increment) }
        : service
    );
    setAdditionalServices(updatedServices);
  };

  const handleAdditionalServiceChange = (
    index: number,
    field: keyof Service,
    value: string | number
  ): void => {
    const updatedServices = additionalServices.map((service, i) =>
      i === index ? { ...service, [field]: value } : service
    );
    setAdditionalServices(updatedServices);
  };

  const handleCheckboxChange = (id: number | string, isAdditional: boolean): void => {
    const updateChecked = (list: Service[]) =>
      list.map((service) =>
        service.id === id ? { ...service, checked: !service.checked } : service
      );

    if (isAdditional) {
      setAdditionalServices(updateChecked(additionalServices));
    } else {
      setServices(updateChecked(services));
    }
  };

  const calculateTotalPrice = (basePrice: number, quantity: number): string => {
    return (basePrice * quantity).toFixed(2);
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h2">Select Cross-sell Services</Typography>
        <Typography className={classes.subtitle}>
          Extra service charges will apply for additional guests.
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div className={classes.headerRow}>
          <div></div>
          <div>SERVICE</div>
          <div>QUANTITY</div>
          <div>BASE PRICE</div>
          <div>TOTAL PRICE</div>
          <div>ACTION</div>
        </div>

        {services.map((service) => (
          <div key={service.id} className={classes.serviceRow}>
            <Checkbox
              checked={service.checked}
              onChange={() => handleCheckboxChange(service.id, false)}
              className={classes.checkbox}
            />
            <Typography>{service.name}</Typography>
            <div className={classes.quantityControl}>
              <IconButton
                className={classes.iconButton}
                size="small"
                onClick={() => handleServiceQuantityChange(service.id, -1)}
                disabled={service.quantity <= 1}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
              <input type="text" value={service.quantity} readOnly />
              <IconButton
                className={classes.iconButton}
                size="small"
                onClick={() => handleServiceQuantityChange(service.id, 1)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
            <TextField
              variant="outlined"
              size="small"
              value={`₹ ${service.basePrice}`}
              className={classes.priceField}
              InputProps={{ readOnly: true }}
            />
            <Typography className={classes.totalPrice}>
              ₹ {calculateTotalPrice(service.basePrice, service.quantity)}
            </Typography>
          </div>
        ))}

        <div className={classes.serviceAction}>
          {additionalServices.map((service, index) => (
            <div
              key={`additional-service-${index}`}
              className={classes.additionalServiceRow}
              style={{ marginTop: 10 }}
            >
              <TextField
                placeholder="Enter service"
                variant="outlined"
                fullWidth
                value={service.name}
                onChange={(e) =>
                  handleAdditionalServiceChange(index, 'name', e.target.value)
                }
                className={classes.serviceNameField}
              />
              <div className={classes.quantityControl}>
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  onClick={() => handleAdditionalServiceQuantityChange(index, -1)}
                  disabled={service.quantity <= 1}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
                <input type="text" value={service.quantity} readOnly />
                <IconButton
                  className={classes.iconButton}
                  size="small"
                  onClick={() => handleAdditionalServiceQuantityChange(index, 1)}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </div>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                value={service.basePrice}
                onChange={(e) =>
                  handleAdditionalServiceChange(
                    index,
                    'basePrice',
                    parseFloat(e.target.value) || 0
                  )
                }
                className={classes.priceField}
              />
              <Typography className={classes.totalPrice}>
                ₹ {calculateTotalPrice(service.basePrice, service.quantity)}
              </Typography>
              <IconButton
                className={classes.deleteButton}
                onClick={() => handleDeleteService(index)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            variant="contained"
            className={classes.saveButton}
            disabled={disabledServices}
            onClick={handleAddService}
          >
            Add Service
          </Button>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.cancelButton}>
          Cancel
        </Button>
        <Button variant="contained" className={classes.saveButton}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrossSellModal;